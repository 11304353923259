<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import layout from "simple-keyboard-layouts/build/layouts/french";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    input: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    keyboard: null,
  }),
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      theme: "hg-theme-default cav",
      buttonTheme: [
        {
          class: "cav",
          buttons:
            "` 1 2 3 4 5 6 7 8 9 0 \u00B0 + a z e r t y u i o p ^ $ q s d f g h j k l m \u00F9 * < w x c v b n , ; : ! \u00B2 & \u00E9 \" ' ( - \u00E8 _ \u00E7 \u00E0 ) = A Z E R T Y U I O P \u00A8 \u00A3 Q S D F G H J K L M % \u00B5 > W X C V B N ? . / \u00A7", //,
        },
        {
          class: "cav-other",
          buttons: "{bksp} {enter} {shift} {lock} {tab} @ {space} .com",
        },
      ],
      ...layout,
    });
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
};
</script>

<style>
.cav {
  background-color: #a69578;
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-keyboard.hg-layout-default .hg-button.cav {
  background: #f5f5f5;
  color: #a69578;
  height: 65px;
  max-height: 65px;
  width: 65px;
  max-width: 65px;
  font-weight: bold;
}
.simple-keyboard.hg-layout-shift .hg-button.cav {
  background: #f5f5f5;
  color: #a69578;
  height: 65px;
  max-height: 65px;
  width: 65px;
  max-width: 65px;
  font-weight: bold;
}

.simple-keyboard.hg-layout-shift .hg-button.cav-other {
  background: #f5f5f5;
  color: #a69578;
  height: 65px;
  max-height: 65px;
  font-weight: bold;
}
.simple-keyboard.hg-layout-default .hg-button.cav-other {
  background: #f5f5f5;
  color: #a69578;
  height: 65px;
  max-height: 65px;
  font-weight: bold;
}
</style>
